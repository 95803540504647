<template>
  <v-container class="d-flex align-center justify-center fill-height">
    <v-card elevation="5" border="md opacity-4" width="400" class="mx-auto pa-8">
      <v-img class="pa-4 mx-auto" width="200px" :src="require('@/assets/logo.png')" contain></v-img>
      <ValidationObserver ref="observer" v-slot="{ invalid }">
        <v-form @submit.prevent id="loginForm">
          <ValidationProvider v-slot="{ errors }" name="Email" rules="required">
            <v-text-field
              prepend-icon="mdi-email"
              v-model="email"
              :error-messages="errors"
              outlined
              name="Email"
              :label="$t('login.emailLabel')"
              type="text"
            ></v-text-field>
          </ValidationProvider>

          <ValidationProvider
            v-slot="{ errors }"
            :name="$t('login.passwordName')"
            :rules="{ required: true }"
          >
            <v-text-field
              id="password"
              :error-messages="errors"
              prepend-icon="mdi-lock"
              :name="$t('login.passwordName')"
              outlined
              :label="$t('login.passwordLabel')"
              v-model="password"
              @keyup.enter="submitForm"
              :type="showPassword ? 'text' : 'password'"
              @click:append="showPassword = !showPassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            ></v-text-field>
          </ValidationProvider>
          <v-col cols="12" class="text-right">
            <v-btn
              block
              color="primary"
              :disabled="invalid"
              :loading="loading"
              @click="submitForm"
              class="text-transform"
              >Login
              <v-icon small right>mdi-exit-to-app</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" class="text-right pt-3">
            <router-link class="primary--text" :to="{ name: 'register' }"
              >{{ $t("login.register") }}
            </router-link>
            <v-spacer></v-spacer>
            <router-link class="primary--text" :to="{ name: 'forgotPassword' }"
              >{{ $t("login.forgotPassword") }}
            </router-link>
          </v-col>
        </v-form>
      </ValidationObserver>
    </v-card>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import { ValidationObserver } from "vee-validate";

export default {
  components: {
    ValidationObserver
  },
  data() {
    return {
      email: null,
      showPassword: false,
      password: null,
      text: ""
    };
  },
  computed: {
    ...mapGetters({
      loading: "options/loading"
    })
  },
  methods: {
    async submitForm() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        const credentials = {
          email: this.email,
          password: this.password
        };
        await this.$auth
          .login({
            data: credentials,
            rememberMe: true,
            redirect: true,
            staySignedIn: true,
            fetchUser: true
          })
          .then(async (response) => {
            const { result } = response.data;
            this.$auth.token("accessToken", result.accessToken, false);
            this.$auth.token("refreshToken", result.refreshToken, false);
            this.$auth.user(JSON.parse(JSON.stringify(this.$auth.user())));
            this.$auth.remember(JSON.stringify(this.$auth.user()));
            if (this.$auth.user().role == 1) {
              this.$router.push({ name: "dashboard" });
            } else {
              this.$router.push({ name: "app-dashboard" });
            }
          });
      }
    }
  }
};
</script>
<style scoped></style>
